import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import AboutSpeedwayDetailed from "../components/AboutSpeedwayDetailed/AboutSpeedwayDetailed"
import BiographyBullets from "../components/BiographyBullets/BiographyBullets";

export default function AboutPage() {

    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`About | Team Max`} />
        </div>
        <AboutSpeedwayDetailed></AboutSpeedwayDetailed>
        <BiographyBullets></BiographyBullets>
      </Layout>
    );
}

