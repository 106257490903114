import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import "./biographyBullets.scss"

const BiographyBullets = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/bio2.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    `)

    return (
        <BackgroundImage
        Tag="section"
        className={"BiographyBullets"}
        fluid={data.file.childImageSharp.fluid}
        title="Max's BiographyBullets">
          <div className="BiographyBullets__inner">
            <h2 className="BiographyBullets__title">Max Fricke</h2>
            <ul className="BiographyBullets__bullets">
              <li>Born March 1996, Mansfield, South Australia</li>
              <li>Signed as a professional speedway rider aged 16 </li>
              <li>2013, 2014, 2015 &amp; 2017 Australian U21 Champion</li>
              <li>2016 U21 World Champion</li>
              <li>2019 &amp; 2020 Australian Senior Solo Champion</li>
              <li>2020 League representation:
                  <ul>
                    <li>Sparta Wroclaw [Polish Ekstraliga]</li>
                    <li>Indianerna Kumla [Swedish Elitserien]</li>
                    <li>Region Varde</li>
                    <li>[Danish Speedway Liga]</li>
                  </ul>
              </li>
              </ul>
              <p className="BiographyBullets__text">Some people are made for speedway. It’s in their DNA. They want to start early, probably before they can walk or even crawl. Max Frinke is one of those people. As the 2016 U21 Speedway World Champion, 4 Time Australian Under 21 Speedway Champion (2013, 2014, 2015 & 2017) and two time Australian Senior Solo Speedway Champion (2019 & 2020), few will argue with his accomplishments. Having travelled the world, he’s set on leaving a legacy in the sport he loves.</p>
          </div>
        </BackgroundImage>
    )
}

export default BiographyBullets